module.exports = {
  siteMetadata: {
    title: 'Alma - Le paiement en plusieurs fois garanti. Simple.',
    description:
      "Alma, la solution de paiement en plusieurs fois garanti pour le e-commerce et les magasins. Simple, sans engagement, sans frais d'installation.",
    siteUrl: `https://getalma.eu`,
    image: '/ogimage/defaultog.jpg',
  },
  pathPrefix: '/alma-corporate-website',
  plugins: [
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/local-cms/`,
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        output: `/gatsby-sitemap.xml`,
        exclude: [`/editor/`],
        createLinkInHead: false,
        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }
            allSitePage{
              edges {
                node {
                  path
                  context {
                    slug
                    lang
                  }
                }
              }
            }
          }
        `,
        serialize: ({site, allSitePage}) => {
          return allSitePage.edges
            .filter(({node}) => {
              if (
                node.context.lang !== 'en' &&
                node.context.lang !== 'de' &&
                node.context.lang !== 'de-en' &&
                node.context.lang !== 'it' &&
                node.context.lang !== 'it-en' &&
                node.context.lang !== 'nl' &&
                node.context.lang !== 'nl-en'
              ) {
                return true;
              }
            })
            .map((edge) => {
              return {
                url: `${site.siteMetadata.siteUrl}${edge.node.path}`,
                changefreq: `daily`,
                priority: edge.node.path === '/' ? 1 : 0.8,
                links: [
                  {
                    lang: 'fr',
                    url: `${site.siteMetadata.siteUrl}${edge.node.path}`,
                  },
                  {
                    lang: 'en-fr',
                    url: `${site.siteMetadata.siteUrl}/fr-en/${edge.node.context.slug}`,
                  },
                  {
                    lang: 'es',
                    url: `${site.siteMetadata.siteUrl}/es/${edge.node.context.slug}`,
                  },
                  {
                    lang: 'en-es',
                    url: `${site.siteMetadata.siteUrl}/es-en/${edge.node.context.slug}`,
                  },
                  {
                    lang: 'nl-be',
                    url: `${site.siteMetadata.siteUrl}/be-nl/${edge.node.context.slug}`,
                  },
                  {
                    lang: 'fr-be',
                    url: `${site.siteMetadata.siteUrl}/be-fr/${edge.node.context.slug}`,
                  },
                ],
              };
            });
        },
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    'gatsby-plugin-sass',
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://getalma.eu`,
      },
    },
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        // Though you should never expose your secret API key
        // in any public website's client-side code,
        // tokens found on the dashboard however are read only
        // and therefore fine to use in a client-side code.
        // https://www.storyblok.com/docs/api/content-delivery#core-resources/stories/stories
        accessToken: 'GkNfB5r1AN9nr0CIpseUKwtt',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolveRelations: ['global.reference', 'globalTagReference.reference'],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        icon: 'src/assets/favicon.png',
      },
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-WJ8F2QJ',
      },
    },
  ],
};
